import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAuth from '../auth/useAuth';
import EmptyLink from '../links/EmptyLink';
import RouterLink from '../links/RouterLink';

export default function AuthButton() {
  const auth = useAuth();
  const match = useRouteMatch('/login');

  return auth.user ? (
    <EmptyLink
      className="text-salmon px-4 hover:text-puce flex items-center"
      onClick={() => {
        auth.logout?.();
      }}
    >
      <FontAwesomeIcon icon={faSignOutAlt} />
    </EmptyLink>
  ) : match ? (
    <RouterLink
      className="text-salmon px-4 hover:text-puce flex items-center font-semibold"
      to="/signup"
    >
      Sign up
    </RouterLink>
  ) : (
    <RouterLink
      className="text-salmon px-4 hover:text-puce flex items-center font-semibold"
      to="/login"
    >
      Log in
    </RouterLink>
  );
}
