import React from 'react';
import ButtonBase, { ButtonProps } from './ButtonBase';
import classNames from 'classnames';

const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <ButtonBase
      {...props}
      ref={ref}
      className={classNames(
        'border border-transparent py-2 px-3 bg-apricot font-medium shadow-md text-base hover:bg-sandy focus:ring-2 focus:ring-offset-1 focus:ring-opacity-75 focus:ring-purple-600 rounded-lg',
        props.className,
      )}
    >
      {props.children}
    </ButtonBase>
  ),
);

PrimaryButton.displayName = 'PrimaryButton';

export default PrimaryButton;
