import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import DashboardRoute from './dashboard/DashboardRoute';

import ProvideAuth from './auth/ProvideAuth';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
import Home from './home/Home';

import './App.module.css';
import Loading from './loading/Loading';

const App = () => (
  <ProvideAuth>
    <Router>
      {/* TODO: match loading fallback styling to home page */}
      <Suspense fallback={<Loading />}>
        <Switch>
          <DashboardRoute path="/app">
            <Dashboard />
          </DashboardRoute>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  </ProvideAuth>
);

export default App;
