import React from 'react';

export default function Header({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...rest}
      className="bg-darkerPurple w-full h-12 flex flex-row items-stretch justify-between"
    >
      {children}
    </div>
  );
}
