import jwtDecode from 'jwt-decode';

import api from '../api';

const TMP_USER_STRING = 'user';

const LOCAL_STORAGE_KEY_BEARER_TOKEN = 'bearerToken';

export default class AuthService {
  private static getAuthorizationHeader() {
    return localStorage.getItem(LOCAL_STORAGE_KEY_BEARER_TOKEN);
  }

  static getTokenExpirationMs(
    bearerToken = this.getAuthorizationHeader(),
  ): number | null {
    if (!bearerToken) {
      return null;
    }
    const decodedToken: { exp?: number } | null = jwtDecode(bearerToken);

    if (decodedToken?.exp) {
      return decodedToken.exp * 1000;
    }

    return null;
  }

  private static setAuthorizationHeader(bearerToken: string) {
    api.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;
    localStorage.setItem(LOCAL_STORAGE_KEY_BEARER_TOKEN, bearerToken);
  }

  static initialize() {
    const bearerToken = this.getAuthorizationHeader();
    if (!bearerToken) {
      return null;
    }

    const expirationMs = this.getTokenExpirationMs(bearerToken);
    if (!expirationMs) {
      return null;
    }

    const nowMs = new Date().getTime();
    if (expirationMs <= nowMs) {
      return null;
    }

    this.setAuthorizationHeader(bearerToken);
    return TMP_USER_STRING;
  }

  static async login(email: string, password: string) {
    const { data } = await api.post('authentication/login', {
      email,
      password,
    });

    if (!data.accessToken) {
      throw new Error('No access token returned');
    }

    this.setAuthorizationHeader(data.accessToken);

    return TMP_USER_STRING;
  }

  static async join(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) {
    // TODO: use user model, pass around
    const { data } = await api.post('users', {
      email,
      password,
      firstName,
      lastName,
    });

    if (!data.accessToken) {
      throw new Error('No access token returned');
    }

    this.setAuthorizationHeader(data.accessToken);

    return TMP_USER_STRING;
  }

  static logout() {
    api.defaults.headers.common['Authorization'] = undefined;
    localStorage.removeItem(LOCAL_STORAGE_KEY_BEARER_TOKEN);
  }
}
