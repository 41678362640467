import classNames from 'classnames';
import { LocationDescriptorObject } from 'history';
import { isString } from 'lodash';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavLink, NavLinkProps, matchPath } from 'react-router-dom';

type RouterLinkProps = NavLinkProps & {
  inactiveClassName?: string;
};

const RouterLink = ({
  children,
  className,
  activeClassName,
  inactiveClassName,
  ...rest
}: RouterLinkProps) => {
  const location = useLocation();
  const isMatch = matchPath(location.pathname, {
    path: isString(rest.to)
      ? rest.to
      : (rest.to as LocationDescriptorObject).pathname || '',
    exact: true,
    strict: true,
  });

  return (
    <NavLink
      {...rest}
      exact
      className={classNames(
        className || 'text-purple-700 hover:text-purple-500',
        !isMatch && inactiveClassName,
      )}
      activeClassName={
        activeClassName || 'text-salmon hover:text-salmon font-semibold'
      }
    >
      {children}
    </NavLink>
  );
};

export default RouterLink;
