import React from 'react';
import { useState } from 'react';
import api from '../api';
import InputBase from '../inputs/InputBase';
import TextAreaBase from '../inputs/TextAreaBase';
import PrimaryButton from '../buttons/PrimaryButton';

export default function ContactPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [email, setEmail]: [string, any] = useState('');
  const [firstName, setFirstName]: [string, any] = useState('');
  const [lastName, setLastName]: [string, any] = useState('');
  const [message, setMessage]: [string, any] = useState('');
  // const [errorMessage, setErrorMessage]: [string | undefined, any] = useState();

  const resetErrorMessage = () => {
    // setErrorMessage();
  };

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    resetErrorMessage();
  };

  const onFirstNameChange = (event: any) => {
    setFirstName(event.target.value);
    resetErrorMessage();
  };

  const onLastNameChange = (event: any) => {
    setLastName(event.target.value);
    resetErrorMessage();
  };

  const onMessageChange = (event: any) => {
    setMessage(event.target.value);
    resetErrorMessage();
  };

  const submit = async () => {
    setLoading(true);

    try {
      await api.post('users/contact', {
        email,
        firstName,
        lastName,
        message,
      });

      setSuccess(true);
    } catch (error) {
      //
    }

    setLoading(false);
  };

  return (
    <div className="container max-w-lg mx-auto px-4 py-6">
      {!!success && <h3>Your message was sent successfully.</h3>}
      {!success && (
        <>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <label>
                <div className="mb-2">First name:</div>
                <InputBase
                  className="rounded border border-gray-200 w-full px-1 py-2"
                  name="firstName"
                  value={firstName}
                  onChange={onFirstNameChange}
                />
              </label>
            </div>
            <div className="mb-4">
              <label>
                <div className="mb-2">Last name:</div>
                <InputBase
                  className="rounded border border-gray-200 w-full px-1 py-2"
                  name="lastName"
                  value={lastName}
                  onChange={onLastNameChange}
                />
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label>
              <div className="mb-2">Email:</div>
              <InputBase
                className="rounded border border-gray-200 w-full px-1 py-2"
                type="email"
                name="email"
                value={email}
                onChange={onEmailChange}
              />
            </label>
          </div>
          <div className="mb-4">
            <label>
              <div className="mb-2">Message:</div>
              <TextAreaBase
                className="rounded border border-gray-200 w-full px-1 py-2"
                name="message"
                value={message}
                onChange={onMessageChange}
              />
            </label>
          </div>
          <div className="mt-8">
            <PrimaryButton onClick={submit} disabled={loading}>
              Submit
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
}
