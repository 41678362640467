import { useContext, createContext } from 'react';

interface AuthContext {
  user?: any;
  login?: (email: string, password: string) => Promise<void>;
  join?: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => Promise<void>;
  logout?: () => void;
}

const defaultAuth: AuthContext = {};
export const authContext = createContext(defaultAuth);

const useAuth: () => AuthContext = () => {
  return useContext(authContext);
};

export default useAuth;
