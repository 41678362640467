import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface PhrasesState {
  count: number;
}

const initialState: PhrasesState = {
  count: 0,
};

export const phrasesSlice = createSlice({
  name: 'phrases',
  initialState,
  reducers: {
    reset: (state) => {
      state.count = 0;
    },
    increment: (state) => {
      state.count = state.count + 1;
    },
  },
});

export const { reset, increment } = phrasesSlice.actions;

export const selectPhraseActionCount: (state: RootState) => number = (
  state: RootState,
) => state.phrases.count;

export default phrasesSlice.reducer;
