import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

type TextAreaProps = React.HTMLProps<HTMLTextAreaElement> & {
  onResize?: () => void;
};

export default function TextAreaBase({
  className,
  onChange,
  onResize,
  autoFocus,
  ...rest
}: TextAreaProps) {
  const [originalHeight, setOriginalHeight] = useState<number>();
  const ref = useRef<HTMLTextAreaElement>(null);

  const adjustSizeOnChange: React.FormEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    resize();
    return onChange?.(event);
  };

  const resize = () => {
    if (!ref.current) {
      return;
    }

    ref.current.style.height = '0';
    if (originalHeight && originalHeight !== ref.current.scrollHeight) {
      onResize?.();
    }
    setOriginalHeight(ref.current.scrollHeight);
    ref.current.style.height = `${ref.current.scrollHeight + 2}px`;
  };

  useEffect(resize, []);
  useEffect(resize, [className]);
  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, [autoFocus]);

  return (
    <textarea
      {...rest}
      ref={ref}
      autoFocus={autoFocus}
      onChange={adjustSizeOnChange}
      className={classNames(
        'border-gray-200 resize-none overflow-y-hidden',
        className ||
          'px-1 py-1/2 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-opacity-75 focus:ring-purple-600',
      )}
    />
  );
}
