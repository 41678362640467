import React from 'react';

import classNames from 'classnames';

export default function EmptyLink({
  children,
  onClick,
  className,
  ...rest
}: React.HTMLProps<HTMLAnchorElement>) {
  return (
    <a
      {...rest}
      className={classNames('text-purple-700 hover:text-purple-500', className)}
      href=""
      onClick={(event, ...rest) => {
        event.preventDefault();
        event.stopPropagation();
        onClick?.(event, ...rest);
      }}
    >
      {children}
    </a>
  );
}
