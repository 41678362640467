import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthButton from './AuthButton';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import RouterLink from '../links/RouterLink';
import JoinPage from './JoinPage';
import Footer from './Footer';
import PricingPage from './PricingPage';
import AboutPage from './AboutPage';
import ContactPage from './ContactPage';
import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';
import Header from '../header/Header';
import useAuth from '../auth/useAuth';
import AppWrapper from '../AppWrapper';
import Logo from '../logo/Logo';

export default function Home() {
  const auth = useAuth();

  return (
    <AppWrapper>
      <Header>
        <div className="flex flex-row">
          <RouterLink to="/" className="text-salmon px-4 hover:text-puce">
            <Logo />
          </RouterLink>
        </div>
        <div className="flex flex-row">
          {!!auth.user && (
            <RouterLink
              className="text-salmon px-4 hover:text-puce font-semibold flex items-center"
              to="/app"
            >
              Dashboard
            </RouterLink>
          )}
          <AuthButton />
        </div>
      </Header>
      <div className="flex-grow flex flex-col">
        <div className="flex-grow">
          <Switch>
            <Route path="/pricing">
              <PricingPage />
            </Route>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/terms">
              <TermsPage />
            </Route>
            <Route path="/privacy">
              <PrivacyPage />
            </Route>
            <Route path="/signup">
              <JoinPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </AppWrapper>
  );
}
