import React, { useState } from 'react';

import { authContext } from './useAuth';
import AuthService from './AuthService';

export default function ProvideAuth({
  children,
}: React.HTMLProps<HTMLDivElement>) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useProvideAuth() {
  const [user, setUser]: [string | null, any] = useState(
    AuthService.initialize(),
  );

  let expirationId: number;

  const expireToken = () => {
    if (expirationId) {
      window.clearTimeout(expirationId);
    }

    if (!user) {
      return;
    }

    const expirationMs = AuthService.getTokenExpirationMs();
    if (!expirationMs) {
      logout();
      return;
    }

    const nowMs = new Date().getTime();
    // TODO: smoother logout
    expirationId = window.setTimeout(logout, expirationMs - nowMs);
  };

  const login = async (email: string, password: string) => {
    const authenticatedUser = await AuthService.login(email, password);
    // TODO: save actual user
    setUser(authenticatedUser);
    expireToken();
  };

  const join = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ) => {
    const authenticatedUser = await AuthService.join(
      email,
      password,
      firstName,
      lastName,
    );
    // TODO: save actual user
    setUser(authenticatedUser);
    expireToken();
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
  };

  expireToken();

  return {
    user,
    login,
    join,
    logout,
  };
}
