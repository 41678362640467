import React from 'react';
import classNames from 'classnames';

export type ButtonProps = React.HTMLProps<HTMLButtonElement>;

const ButtonBase = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, type = 'button', ...rest }, ref) => (
    <button
      {...rest}
      ref={ref}
      className={classNames(
        'whitespace-nowrap font-medium disabled:opacity-50 focus:ring-2 focus:ring-offset-1 focus:ring-opacity-75 focus:ring-purple-600 focus:outline-none disabled:cursor-default',
        className || 'text-base text-purple-700 hover:text-purple-500',
      )}
      type={type as 'button' | 'submit' | 'reset'}
    >
      {children}
    </button>
  ),
);

ButtonBase.displayName = 'ButtonBase';

export default ButtonBase;
