import classNames from 'classnames';
import React from 'react';

export default function InputBase({
  type = 'text',
  className,
  ...rest
}: React.HTMLProps<HTMLInputElement>) {
  return (
    <input
      {...rest}
      className={classNames(
        'border-gray-200 hover:border-gray-200',
        className ||
          'px-1 py-1/2 active:outline-none focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-opacity-75 focus:ring-purple-600',
      )}
      type={type}
    />
  );
}
