import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import useAuth from '../auth/useAuth';
import { useState } from 'react';
import InputBase from '../inputs/InputBase';
import PrimaryButton from '../buttons/PrimaryButton';
import Form from '../forms/Form';
import { AxiosError, AxiosResponse } from 'axios';
import isArray from 'lodash/isArray';

export default function JoinPage() {
  const [loading, setLoading]: [boolean, any] = useState(false);
  const [email, setEmail]: [string, any] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [password, setPassword]: [string, any] = useState('');
  const [firstName, setFirstName]: [string, any] = useState('');
  const [lastName, setLastName]: [string, any] = useState('');

  const history = useHistory();
  const auth = useAuth();

  const join = async () => {
    setLoading(true);
    try {
      await auth.join?.(email, password, firstName, lastName);
      history.replace({ pathname: '/app' });
    } catch (error) {
      const message = ((error as AxiosError)?.response as AxiosResponse)?.data
        ?.message;

      setErrorMessages(
        isArray(message) ? message : ['Invalid email, password, or first name'],
      );
      setLoading(false);
    }
  };

  const resetErrorMessage = () => {
    setErrorMessages(undefined);
  };

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    resetErrorMessage();
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    resetErrorMessage();
  };

  const onFirstNameChange = (event: any) => {
    setFirstName(event.target.value);
    resetErrorMessage();
  };

  const onLastNameChange = (event: any) => {
    setLastName(event.target.value);
    resetErrorMessage();
  };

  return auth.user ? (
    <Redirect to="/app" />
  ) : (
    <div className="container max-w-lg mx-auto px-4 py-6">
      <Form onSubmit={join} className="flex flex-col space-y-4">
        <h1 className="mb-4 font-semibold text-2xl">Sign up</h1>
        <label className="flex flex-col space-y-2">
          <div>First name</div>
          <InputBase
            className="rounded border border-gray-200 w-full px-1 py-2"
            name="firstName"
            value={firstName}
            onChange={onFirstNameChange}
          />
        </label>
        <label className="flex flex-col space-y-2">
          <div>Last name</div>
          <InputBase
            className="rounded border border-gray-200 w-full px-1 py-2"
            name="lastName"
            value={lastName}
            onChange={onLastNameChange}
          />
        </label>
        <label className="flex flex-col space-y-2">
          <div>Email</div>
          <InputBase
            className="rounded border border-gray-200 w-full px-1 py-2"
            type="email"
            name="email"
            value={email}
            onChange={onEmailChange}
          />
        </label>
        <label className="flex flex-col space-y-2">
          <div>Password</div>
          <InputBase
            className="rounded border border-gray-200 w-full px-1 py-2"
            type="password"
            name="password"
            value={password}
            onChange={onPasswordChange}
          />
        </label>
        {!!errorMessages?.length && (
          <div className="text-red-700 flex flex-col space-y-1">
            {errorMessages?.map((message, index) => (
              <p key={`join-error-${index}`}>{message}</p>
            ))}
          </div>
        )}
        <div>
          <PrimaryButton onClick={join} disabled={loading}>
            Submit
          </PrimaryButton>
        </div>
      </Form>
    </div>
  );
}
