import React, {
  FormEventHandler,
  HTMLProps,
  KeyboardEventHandler,
} from 'react';

type FormProps = Omit<HTMLProps<HTMLFormElement>, 'onSubmit'> & {
  onSubmit?: () => void;
};

const Form: React.FC<FormProps> = ({ children, onSubmit, ...rest }) => {
  const onSubmitWrapper: FormEventHandler<HTMLFormElement> = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    onSubmit?.();
  };

  const onKeyPress: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.which === 13 || event.keyCode === 13 || event.key === 'Enter') {
      onSubmitWrapper(event);
    }
  };

  return (
    <form {...rest} onSubmit={onSubmitWrapper} onKeyPress={onKeyPress}>
      {children}
    </form>
  );
};

export default Form;
