import React from 'react';

import RouterLink from '../links/RouterLink';
import useAuth from '../auth/useAuth';
import Logo from '../logo/Logo';

export default function Footer() {
  const auth = useAuth();

  return (
    <div className="bg-darkerPurple text-left">
      <div className="container mx-auto px-4 py-6 flex flex-row space-x-10">
        <div className="flex flex-col">
          <RouterLink to="/" className="text-salmon px-4 py-3 hover:text-puce">
            <Logo />
          </RouterLink>
        </div>
        <div className="flex flex-col">
          {/* <RouterLink
            to="/pricing"
            className="text-salmon px-4 py-3 hover:text-puce"
          >
            Pricing
          </RouterLink> */}
          {/* <RouterLink
            to="/about"
            className="text-salmon px-4 py-3 hover:text-puce"
          >
            About Us
          </RouterLink> */}
          {!auth.user && (
            <RouterLink
              to="/login"
              className="text-salmon px-4 py-3 hover:text-puce"
            >
              Log in
            </RouterLink>
          )}
          {!auth.user && (
            <RouterLink
              to="/signup"
              className="text-salmon px-4 py-3 hover:text-puce"
            >
              Sign up
            </RouterLink>
          )}
          {!!auth.user && (
            <RouterLink
              className="text-salmon px-4 py-3 hover:text-puce font-semibold"
              to="/app"
            >
              Dashboard
            </RouterLink>
          )}
          <RouterLink
            to="/contact"
            className="text-salmon px-4 py-3 hover:text-puce"
          >
            Contact Us
          </RouterLink>
        </div>
        <div className="flex flex-col">
          {/* <RouterLink
            to="/terms"
            className="text-salmon px-4 py-3 hover:text-puce"
          >
            Terms of Service
          </RouterLink> */}
          {/* <RouterLink
            to="/privacy"
            className="text-salmon px-4 py-3 hover:text-puce"
          >
            Privacy Policy
          </RouterLink> */}
        </div>
      </div>
    </div>
  );
}
