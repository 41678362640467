import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const Logo = () => (
  <div className="flex space-x-3 items-center h-full">
    <FontAwesomeIcon icon={faCircle} />
    <div className="text-xs font-semibold">rosegold</div>
  </div>
);

export default Logo;
